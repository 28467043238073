import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';

import '../../generic-page.scss';
import './guides.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { Card, Col, Row } from 'react-bootstrap';
import { ZZZCharacter } from '../../../modules/zzz/common/components/zzz-character';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const ZZZAgentsAttri: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page zzz guide-zzz'} game="zzz">
      <ul className="breadcrumb">
        <li>
          <Link to="/zenless/">Zenless Zone Zero</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/zenless/guides/">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Attributes & Specialties</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/zzz/categories/category_intro.png"
            alt="Characters"
          />
        </div>
        <div className="page-details">
          <h1>Attributes & Specialties</h1>
          <h2>
            Agents Attributes and Specialties in Zenless Zone Zero explained.
          </h2>
          <p>
            Last updated: <strong>15/12/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <div className="page-content">
        <SectionHeader title="Agents" />
        <StaticImage
          src="../../../images/zzz/guides/characters.webp"
          alt="Guide"
        />
        <p>
          Agents are playable units in ZZZ. You are given{' '}
          <strong>3 agents for free (Anby, Nicole and Billy)</strong>:
        </p>
        <div className="zzz-container">
          <Card className="avatar-card">
            <ZZZCharacter
              slug="anby-demara"
              mode="card"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <ZZZCharacter
              slug="nicole-demara"
              mode="card"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <ZZZCharacter
              slug="billy-kid"
              mode="card"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <p>
          And those Agents you can obtain for free from progressing through the
          game and from various events:
        </p>
        <div className="zzz-container">
          <Card className="avatar-card">
            <ZZZCharacter
              slug="ben"
              mode="card"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <ZZZCharacter
              slug="corin"
              mode="card"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <ZZZCharacter
              slug="soukaku"
              mode="card"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <p>Sources:</p>
        <ul>
          <li>
            <strong>Ben</strong> - complete Chapter 2 Story 1,
          </li>
          <li>
            <strong>Corin</strong> - pre-register reward,
          </li>
          <li>
            <strong>Soukaku</strong> - complete Shiyu Defense 3-4,
          </li>
        </ul>
        <p>
          To gain more you’ll need to engage with the game’s Gacha System Signal
          Warp:
        </p>
        <StaticImage
          src="../../../images/zzz/guides/banners.webp"
          alt="Guide"
        />
        <SectionHeader title="Rarities" />
        <div className="zzz-container">
          <Card className="avatar-card">
            <ZZZCharacter
              slug="koleda"
              mode="card"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <ZZZCharacter
              slug="ben"
              mode="card"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <p>
          Agents are split into two rarities:{' '}
          <strong className="s-rank">S-rank</strong> and{' '}
          <strong className="a-rank">A-rank</strong>, with S-rank Agents being
          more powerful and harder to get on average.
        </p>
        <SectionHeader title="Stats" />
        <StaticImage src="../../../images/zzz/guides/stats.webp" alt="Guide" />
        <p>Here are all the combat stats agents can gain and what they do:</p>
        <ul>
          <li>
            <strong> HP</strong> - How much damage an Agent can take before
            falling in combat.
          </li>
          <li>
            <strong>ATK</strong> - How much damage an Agent deals.
          </li>
          <li>
            <strong>DEF</strong> - Reduces the damage an Agent takes.
          </li>
          <li>
            <strong>Impact</strong> - Attacking enemies increases their Daze. A
            higher Impact stat allows more Daze to accumulate with each attack.
          </li>
          <li>
            <strong>Crit Rate</strong> - How likely it is for the Agent to land
            a critical hit.
          </li>
          <li>
            <strong>Crit DMG</strong> - Determines the damage multiplier when
            landing a critical hit.
          </li>
          <li>
            <strong>PEN</strong> - Ignores a set amount of the target’s DEF
            based on the PEN stat when attacking an enemy.
          </li>
          <li>
            <strong>Pen Ratio</strong> - Ignores a percentage of the target’s
            DEF based on the PEN Ratio stat when attacking an enemy.
          </li>
          <li>
            <strong>Anomaly Mastery</strong> - Affects the efficiency of Anomaly
            Buildup. Each point in Anomaly Mastery is equivalent to 1% of
            Anomaly Buildup Rate.
          </li>
          <li>
            <strong>Anomaly Proficiency</strong> - Enhances the damage inflicted
            by Attribute Anomalies. Each point in Anomaly Proficiency is
            equivalent to 1% of Attribute Anomaly DMG.
          </li>
          <li>
            <strong>Energy Regen</strong> - After entering combat, Agents
            passively generate Energy every second. The higher this value is,
            the more Energy gained per second.
          </li>
          <li>
            <strong>Energy Generation Rate</strong> - Energy Generation Rate
            will increase the Energy generated through Energy Regen, and
            attacking enemies.
          </li>
        </ul>
        <SectionHeader title="Attributes" />
        <p>
          All agents are categorized into one of six <strong>Attributes</strong>
          :
        </p>
        <ul>
          <li>
            <strong className="Fire">Fire</strong>
          </li>
          <li>
            <strong className="Electric">Electric</strong>
          </li>
          <li>
            <strong className="Ice">Ice</strong>
          </li>
          <li>
            <strong className="Ice">Frost</strong>
          </li>
          <li>
            <strong className="Physical">Physical</strong>
          </li>
          <li>
            <strong className="Ether">Ether</strong>
          </li>
        </ul>
        <p>
          Combining Agents with specific Elemental attributes and Factions in
          the same squad allows you to activate their additional passive skill.
        </p>
        <p>
          Enemies come in different types and are vulnerable to different
          elemental damage:
        </p>
        <ul>
          <li>
            <strong>Organic and Corrupted</strong> are vulnerable to{' '}
            <strong className="zzz-color Fire">Fire</strong>-Type Attacks.
          </li>
          <li>
            <strong>Machines</strong> are vulnerable to{' '}
            <strong className="zzz-color Electric">Electric</strong>-Type
            Attacks.
          </li>
          <li>
            <strong>Mutants</strong> are vulnerable to{' '}
            <strong className="zzz-color Ice">Ice</strong>-Type Attacks.
          </li>
        </ul>
        <SectionHeader title="Attribute Anomaly" />
        <p>
          When an agent deals attribute damage, they{' '}
          <strong>inflict a certain amount of Anomaly Buildup</strong>. Once a
          certain level of buildup is reached, the enemy will be afflicted with
          an attribute anomaly debuff of the corresponding attribute.
          Additionally, exploiting enemies weakness to certain Attributes to
          trigger Attribute Anomaly will intensify the Anomaly effect. As we
          mentioned in the stats section above,{' '}
          <strong>
            Anomaly Mastery stat will increase the buildup of the Anomaly, while
            Anomaly Proficiency will increase the damage dealt by it.
          </strong>
        </p>
        <p>
          Combining 2 different attributes will override the original anomaly
          and trigger an additional effect <strong>Disorder</strong> which deals
          extra damage calculated based on original anomalies damage and
          accumulates Daze.
        </p>
        <p>Here are all the Anomaly Debuffs that exist in the game:</p>
        <div className="anomaly-debuff-table">
          <div className="ano-header">
            <div>Element</div>
            <div>Activation</div>
            <div>Debuff</div>
          </div>
          <div className="single-row">
            <div>
              <strong className="zzz-color Electric">Electric</strong>
            </div>
            <div>
              <span className="on-mobile">Activation</span>
              Dealing Electric damage to enemies accumulates the Electric
              Attribute Anomaly, which triggers the Shock effect when enough is
              accumulated.
            </div>
            <div>
              <span className="on-mobile">Debuff</span>
              <strong className="zzz-color Electric">Shock:</strong> Being
              attacked intermittently triggers additional Electric damage and
              interrupts enemy actions. Machine enemies are unable to move while
              Shocked.
            </div>
          </div>
          <div className="single-row">
            <div>
              <strong className="zzz-color Physical">Physical</strong>
            </div>
            <div>
              <span className="on-mobile">Activation</span>
              Dealing Physical damage to enemies accumulates the Physical
              Attribute Anomaly, which triggers the Assault effect when enough
              is accumulated.
            </div>
            <div>
              <span className="on-mobile">Debuff</span>
              <ul>
                <li>
                  <strong className="zzz-color Physical">Assault:</strong>{' '}
                  Interrupts the enemy, deals massive Physical damage and
                  increases the Daze inflicted on the target.
                </li>
              </ul>
            </div>
          </div>
          <div className="single-row">
            <div>
              <strong className="zzz-color Ice">Ice</strong>
            </div>
            <div>
              <span className="on-mobile">Activation</span>
              Dealing Ice damage to enemies accumulates the Ice Attribute
              Anomaly, which triggers the Freeze and Frostbite effects when
              enough is accumulated.
            </div>
            <div>
              <span className="on-mobile">Debuff</span>
              <ul>
                <li>
                  <strong className="zzz-color Ice">Freeze:</strong> Prevents
                  taking action for a certain period, and triggers Shatter at
                  the end of the effect, dealing Ice damage.
                </li>
                <li>
                  <strong className="zzz-color Ice">Frostbite:</strong>{' '}
                  Increases the CRIT DMG taken by the target for a period of
                  time.
                </li>
              </ul>
            </div>
          </div>
          <div className="single-row">
            <div>
              <strong className="zzz-color Ice">Frost</strong>
            </div>
            <div>
              <span className="on-mobile">Activation</span>
              Dealing Frost damage to enemies accumulates the Frost Attribute
              Anomaly, which triggers the Freeze and Frostbite effects when
              enough is accumulated.
            </div>
            <div>
              <span className="on-mobile">Debuff</span>
              <ul>
                <li>
                  <strong className="zzz-color Ice">Freeze:</strong> Prevents
                  taking action for a certain period, and triggers Shatter at
                  the end of the effect, dealing Ice damage.
                </li>
                <li>
                  <strong className="zzz-color Ice">Frostbite:</strong>{' '}
                  Increases the CRIT DMG taken by the target for a period of
                  time.
                </li>
              </ul>
            </div>
          </div>
          <div className="single-row">
            <div>
              <strong className="zzz-color Fire">Fire</strong>
            </div>
            <div>
              <span className="on-mobile">Activation</span>
              Dealing Fire damage to enemies accumulates the Fire Attribute
              Anomaly, which triggers the Burn effect when enough is
              accumulated.
            </div>
            <div>
              <span className="on-mobile">Debuff</span>
              <strong className="zzz-color Fire">Burn:</strong> Deals continuous
              Fire damage. Organic enemies are unable to move while Burned.
            </div>
          </div>
          <div className="single-row">
            <div>
              <strong className="zzz-color Ether">Ether</strong>
            </div>
            <div>
              <span className="on-mobile">Activation</span>
              Dealing Ether damage to enemies accumulates the Ether Attribute
              Anomaly, causing the Corruption effect when enough is accumulated.
            </div>
            <div>
              <span className="on-mobile">Debuff</span>
              <strong className="zzz-color Ether">Corruption:</strong> Causes
              additional Ether damage when attacked. Corrupted Ethereal enemies
              will also attack both friend and foe.
            </div>
          </div>
        </div>
        <p>To learn more about Anomaly Debuffs, check this great video:</p>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="bi6IJXh_-9A" />
          </Col>
        </Row>
        <SectionHeader title="Specialties" />
        <p>
          In addition Agents can specialize in five different{' '}
          <strong>Specialties</strong> that define their role in the team:
        </p>
        <ul>
          <li>
            <strong>Attack</strong> - Agents with the Attack specialty possess
            formidable damage output capabilities, excelling at rapidly racking
            up damage directly through attacks to defeat enemies and bring the
            combat to a swift end.
          </li>
          <li>
            <strong>Stun</strong> - Agents with Stun specialty possess powerful
            control abilities, excelling at building up Daze to cause enemies to
            be Stunned, thus creating opportunities for the squad to deal
            damage.
          </li>
          <li>
            <strong>Support</strong> - Agents with Support specialty are able to
            aid and enhance other Agents in battle, excelling at buffing
            friendly units to improve the combat effectiveness of the whole
            squad.
          </li>
          <li>
            <strong>Defense</strong> - Agents with Defense specialty have strong
            survivability, excelling at tanking attacks to counterattack,
            seizing the upper hand for their side in the heat of battle.
          </li>
          <li>
            <strong>Anomaly</strong> - Agents with Anomaly specialty are
            exceptional with applying debuffs, excelling at accumulating Anomaly
            Buildup to weaken enemies and deal damage by triggering Attribute
            Anomalies.
          </li>
        </ul>
        <SectionHeader title="Agent Training" />
        <p>
          If you want to learn more about how to make your Agents stronger,
          check this guide:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Agents Progression"
            link="/zenless/guides/agents-progression"
            image={
              <StaticImage
                src="../../../images/zzz/categories/category_agents.jpg"
                alt="Agents Progression"
              />
            }
          />
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
    </DashboardLayout>
  );
};

export default ZZZAgentsAttri;

export const Head: React.FC = () => (
  <Seo
    title="Attributes & Specialties | Zenless Zone Zero | Prydwen Institute"
    description="Agents Attributes and Specialties in Zenless Zone Zero explained."
    game="zzz"
  />
);
